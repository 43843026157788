import appStore from './appStore';
import modelStore from './modelStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { load } from './service/fetchModel';
import { componentWiseAdd } from 'app/utils/arrayUtils';

export interface IRimState {
  position: number[];
  positionOffset: number[];
  rotation: number[];
  scale: number;
  scaleModifier: number;
  visible: boolean;
}

const initialRimState: IRimState = {
  position: [0, 0, 0],
  positionOffset: [0, 0, 0],
  rotation: [0, 0, 0],
  scale: 1,
  scaleModifier: 1,
  visible: false
};

export default class ARStore {
  firstState: IRimState = initialRimState;
  secondState: IRimState = initialRimState;
  rimCount: number = 0;
  rimsVisible: boolean = true;
  ghostVisible: boolean = true;
  ghostModel: THREE.Group | null = null;
  model: THREE.Group | null = null;

  selectedRim: string | null = null;
  defaultScale: number = 18;
  deleteState: boolean[] = [false, false];

  selectedSizeFirst: number = -1;
  selectedSizeSecond: number = -1;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    runInAction(() => {
      this.firstState = initialRimState;
      this.secondState = initialRimState;
      this.rimCount = 0;
      this.rimsVisible = true;
      this.ghostVisible = true;
      this.defaultScale = 18;
      this.deleteState = [false, false];
      this.selectedSizeFirst = -1;
      this.selectedSizeSecond = -1;
    });
  }

  setSelectedSize(size: number) {
    runInAction(() => {
      if (this.selectedRim === '1') {
        this.selectedSizeFirst = size;
      } else if (this.selectedRim === '2') {
        this.selectedSizeSecond = size;
      } else {
        console.error('No rim selected');
      }
    });
  }

  setGhostVisible(value: boolean) {
    runInAction(() => {
      this.ghostVisible = value;
    });
  }

  selectRim(id: string | null) {
    runInAction(() => {
      this.selectedRim = id;
    });
  }

  setOffset(offset: number[]) {
    runInAction(() => {
      if (this.selectedRim === '1') {
        this.firstState.positionOffset = offset;
      } else if (this.selectedRim === '2') {
        this.secondState.positionOffset = offset;
      } else {
        console.error('No rim selected');
      }
    });
  }

  setRotation(rotation: number) {
    runInAction(() => {
      if (this.selectedRim === '1') {
        this.firstState.rotation = [
          this.firstState.rotation[0],
          rotation,
          this.firstState.rotation[2]
        ];
      } else if (this.selectedRim === '2') {
        this.secondState.rotation = [
          this.secondState.rotation[0],
          rotation,
          this.secondState.rotation[2]
        ];
      } else {
        console.error('No rim selected');
        return null;
      }
    });
  }

  setScale(scale: number) {
    runInAction(() => {
      if (this.selectedRim === '1') {
        this.firstState.scale = scale;
      } else if (this.selectedRim === '2') {
        this.secondState.scale = scale;
      } else {
        console.error('No rim selected');
        return null;
      }
    });
  }

  getParams() {
    if (this.selectedRim === '1') {
      return this.firstState;
    } else if (this.selectedRim === '2') {
      return this.secondState;
    } else {
      console.error('No rim selected');
      return null;
    }
  }

  getFullPosition(id: number) {
    if (id === 1) {
      return componentWiseAdd(
        this.firstState.position,
        this.firstState.positionOffset
      );
    } else if (id === 2) {
      return componentWiseAdd(
        this.secondState.position,
        this.secondState.positionOffset
      );
    } else {
      return [0, 0, 0];
    }
  }

  setFirstParams(
    pos: number[],
    rot: number[],
    scale: number,
    visible: boolean
  ) {
    runInAction(() => {
      this.firstState.position = pos;
      this.firstState.rotation = rot;
      this.firstState.scale = scale;
      this.firstState.visible = visible;
    });
  }

  setSecondParams(
    pos: number[],
    rot: number[],
    scale: number,
    visible: boolean
  ) {
    runInAction(() => {
      this.secondState.position = pos;
      this.secondState.rotation = rot;
      this.secondState.scale = scale;
      this.secondState.visible = visible;
    });
  }

  setRimCount(value: number): void {
    runInAction(() => {
      this.rimCount = value;
    });
  }

  incrementRimCount(): void {
    runInAction(() => {
      this.rimCount++;
    });
  }
  decrementRimCount(): void {
    runInAction(() => {
      this.rimCount--;
    });
  }

  toggleVisibility() {
    runInAction(() => {
      this.rimsVisible = !this.rimsVisible;
    });
  }

  setDeleteState(first: boolean, second: boolean) {
    runInAction(() => {
      this.deleteState = [first, second];
    });
  }

  setScaleModifier(value: number) {
    if (!modelStore.state.selectedRim?.size) return;

    runInAction(() => {
      const modifier = value / modelStore.state.selectedRim?.size!;

      this.firstState.scaleModifier = modifier;
      this.secondState.scaleModifier = modifier;
    });
  }

  setModels(model: THREE.Group, ghost: THREE.Group) {
    runInAction(() => {
      this.model = model;
      this.ghostModel = ghost;
    });
  }

  async changeModel(id: string) {
    appStore.setIsLoading(true);
    const [model, ghost] = await load(id);

    runInAction(() => {
      this.model = model;
      this.ghostModel = ghost;
    });

    appStore.setIsLoading(false);
  }

  removeCurrentlySelectedRim() {
    runInAction(() => {
      if (this.selectedRim === '1') {
        this.firstState = initialRimState;
        this.selectedSizeFirst = -1;
        this.decrementRimCount();
        this.deleteState[0] = false;
      } else if (this.selectedRim === '2') {
        this.secondState = initialRimState;
        this.selectedSizeSecond = -1;
        this.decrementRimCount();
        this.deleteState[1] = false;
      } else {
        console.error('No rim selected');
      }
    });
  }

  setDefaultScale(value: number) {
    runInAction(() => {
      this.defaultScale = value;
    });
  }
}
